export const validateEmail = (mail) => {
  // eslint-disable-next-line
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,63})+$/.test(mail)) return true;
  return false;
};

export const transactionIdValidator = (transactionId) => {
  const regex = /^[a-zA-Z0-9]+$/gm;
  if (regex.test(transactionId)) return true;
  return false;
};

export const validateIBAN = (iban) => {
  if (
    // eslint-disable-next-line
    /^(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/.test(
      iban.replace(/\s/g, "")
    )
  )
    return true;
  return false;
};

export const validatePhone = (phone) => {
  const withOutSpace = phone.replace(/\s/g, "");

  if (withOutSpace.length === 9) return true;
  return false;
};

export const validateBicfi = (bicfi) => {
  // eslint-disable-next-line
  if (/^[A-Z]{6,6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3,3}){0,1}$/.test(bicfi))
    return true;
  return false;
};

export const checkRequired = (fields, data) => {
  const errors = {};

  fields.forEach((fieldName) => {
    if (!data[fieldName]) {
      errors[fieldName] = "Field is required";
    }
  });

  return errors;
};

export const isValidUrl = (url) =>
  // eslint-disable-next-line
  /(blob\:)?(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}/g.test(url);

export const isValidUrlNew = (url) =>
  // eslint-disable-next-line
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim.test(
    url
  );

const validators = {
  email: validateEmail,
  iban: validateIBAN,
  phone: validatePhone,
  bicfi: validateBicfi,
};

export const validator = ({
  value,
  type,
  required,
  message = "Invalid field",
}) => {
  const validationFunction = validators[type];
  let error;

  if (!value && required) return "Field required";
  if (value && type && !validationFunction(value)) return message;

  return error;
};

export const isEmptyObj = (obj) => {
  return (
    obj && // 👈 null and undefined check
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
};

export const formatNumber = (phoneNumberString) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);
  if (match) {
    return `${match[1]} ${match[2]} ${match[3]}`;
  }
  return null;
};
